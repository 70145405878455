<template>
    <div class="container fluid">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-autocomplete v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Ship From(Mill)" clearable dense prepend-icon="mdi-factory"></v-autocomplete>
                            </div>
                            <div class="col-lg-2">
                                <v-autocomplete v-model="typeModel" :items="typeLists" default="" item-value="value" item-text="text" label="Type" dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-autocomplete>
                            </div>
                            <div class="col-lg-2">
                                <v-autocomplete v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" :label=labelRegion clearable dense :disabled="disabled_region == true"  @change="(event) => regionOnChange(event)" prepend-icon="mdi-city"></v-autocomplete>
                            </div>
                            <div class="col-lg-3">
                                <v-autocomplete v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" :label=labelBranch clearable dense :disabled="disabled_office == true"  @change="(event) => officeOnChange(event)" prepend-icon="mdi-home-variant"></v-autocomplete>
                            </div>
                            <div class="col-lg-3">
                                <v-autocomplete v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" :label=labelSales clearable dense :disabled="disabled_salesman == true" prepend-icon="mdi-account"></v-autocomplete>
                            </div>
                            <div class="col-lg-3">
                                <v-autocomplete
                                    v-model="customerModel"
                                    :items="customerLists"
                                    item-value="CustomerId"
                                    item-text="NamaCustomer"
                                    :search-input.sync="searchCustomer"
                                    cache-items
                                    flat
                                    hide-no-data
                                    hide-details
                                    clearable
                                    label="Customer" 
                                    prepend-icon="mdi-account-multiple"
                                    ></v-autocomplete>
                            </div>
                            <div class="col-lg-3">
                                <v-autocomplete v-model="custDivisionModel" :items="custDivisionLists" default="" item-value="str3" item-text="str3" label="Cust. Division" dense prepend-icon="mdi-checkbox-multiple-blank"></v-autocomplete>
                            </div>
                            <div class="col-lg-2">
                                <v-autocomplete
                                    v-model="orderIdModel"
                                    :items="orderIdLists"
                                    item-value="CustomerOrderNo"
                                    item-text="NamaCustomer"
                                    :search-input.sync="searchOrder"
                                    cache-items
                                    flat
                                    hide-no-data
                                    hide-details
                                    clearable
                                    label="Order ID" prepend-icon="mdi-cart"
                                    ></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-autocomplete v-model="outstandingModel" :items="outstandingLists" default="" item-value="outstanding_id" item-text="outstanding_text" label="Outstanding Order" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-autocomplete v-model="typeOrderModel" :items="typeOrderLists" default="" item-value="type_id" item-text="type_text" label="Order Type" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-autocomplete>
                            </div>
                            <div class="col-lg-2">
                                <v-autocomplete v-model="paymentModel" :items="paymentLists" default="" item-value="pay_term" item-text="pay_term" label="Payment Terms" clearable dense prepend-icon="mdi-wallet"></v-autocomplete>
                            </div>
                            <div class="col-lg-2">
                                <v-autocomplete v-model="releaseModel" :items="releaseLists" default="" item-value="value" item-text="text" label="Release Order" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-autocomplete>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-radio-group row v-model="dateTypeModel">
                                    <v-radio label="Order Date" value="order"></v-radio>
                                    <v-radio label="Release Date" value="release"></v-radio>
                                </v-radio-group>
                            </div>
                            <div class="col-lg-2 text-right">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                            <div class="col-lg-12">
                                <v-data-table :headers="headersWgt" :items="itemWgtLists" class="elevation-1" :hide-default-footer="true" dense></v-data-table>
                                <!-- <div class="row">
                                    <div class="col-12 col-lg-3">
                                        <h6 class="text-primary ma-3">Order Wgt. Total : {{ summaryOrdWgtModel ?  summaryOrdWgtModel : 0 }} Tons</h6>
                                    </div>
                                    <div class="col-12 col-lg-3">
                                        <h6 class="text-primary ma-3">Ship. Wgt. Total : {{ summaryShipWgtModel ?  summaryShipWgtModel : 0  }} Tons</h6>
                                    </div>
                                    <div class="col-12 col-lg-3">
                                        <h6 class="text-primary ma-3">Outs. Wgt. Total : {{ summaryOutsWgtModel ?  summaryOutsWgtModel : 0  }} Tons</h6>
                                    </div>
                                    <div class="col-12 col-lg-3">
                                        <h6 class="text-primary ma-3">Return Wgt. Total : {{ summaryRetWgtModel ?  summaryRetWgtModel : 0  }} Tons</h6>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                            <!-- <div class="row">
                                <div class="col-6 col-lg-4">
                                    <h5 class="card-title ma-3">Order Wgt. Total : {{ summaryOrdWgtModel ?  summaryOrdWgtModel : '0' }} Tons</h5>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <h5 class="card-title ma-3">Ship Wgt. Total : {{ summaryShipWgtModel ?  summaryShipWgtModel : '0'  }} Tons</h5>
                                </div>
                                <div class="col-6 col-lg-4">
                                    <h5 class="card-title ma-3">Return Wgt. Total : {{ summaryRetWgtModel ?  summaryRetWgtModel : '0'  }} Tons</h5>
                                </div>
                            </div> -->
                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <!-- <div class="d-flex w-25">
                                            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div> -->
                                        <div class="row">
                                            <div class="col-6">
                                                <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                            <div class="col-6 text-right">
                                                <v-btn class="mt-3" color="green" elevation="2" small rounded @click="exportToExcel()" dark>EXPORT TO EXCEL</v-btn>
                                            </div>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.showDetails`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip @click="showItemDetails(item)" color="blue" link small v-on="on" dark>Details</v-chip>
                                        </template>
                                        <span>Show Detail</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="detailModalLabel">Detail Order of {{ detailModalTitle }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense>
                        <template v-slot:[`item.Panjang`]="{ item }">
                            {{item.Panjang + ' m'}}
                        </template>
                        <template v-slot:[`item.qty_order`]="{ item }">
                            {{item.qty_order }} / {{item.wgtOrder + ' Kg'}}
                        </template>
                        <template v-slot:[`item.qty_ship`]="{ item }">
                            {{item.qty_ship }} / {{item.wgtShip + ' Kg'}}
                        </template>
                        <template v-slot:[`item.qty_ret`]="{ item }">
                            {{item.qty_ret }} / {{item.wgtRetur + ' Kg'}}
                        </template>
                        <template slot="body.append">
                            <tr class="primary--text blue lighten-5">
                                <th class="text-left">TOTAL :</th>
                                <th class="text-right"></th>
                                <th class="text-right"></th>
                                <th class="text-right">{{ summaryTotalOrder ? summaryTotalOrder : 0 }} / {{ summaryWgtOrder ? summaryWgtOrder : 0 }} Kg</th>
                                <th class="text-right"></th>
                                <th class="text-right"></th>
                                <th class="text-right">{{ summaryTotalShip ? summaryTotalShip : 0 }} / {{ summaryWgtShip ? summaryWgtShip : 0 }} Kg</th>
                                <th class="text-right"></th>
                                <th class="text-right"></th>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

import exportFromJSON from "export-from-json"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'E-Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Order Report',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            typeModel: '',
            typeLists: [],
            labelRegion: 'Region',
            regionModel: '',
            regionLists: [],
            labelBranch: 'Branch Office',
            officeModel: '',
            officeLists: [],
            labelSales: 'Salesman',
            salesModel: '',
            salesLists: [],
            customerModel: '',
            customerLists: [],
            custDivisionModel:  '',
            custDivisionLists: [],
            orderIdModel: '',
            orderIdLists: [],
            outstandingModel: '',
            outstandingLists: [
                { outstanding_id: 'Y', outstanding_text: 'YES' },
                { outstanding_id: 'N', outstanding_text: 'NO' },
            ],
            typeOrderModel: '',
            typeOrderLists: [
                { type_id: 'O', type_text: 'CUSTOMER' },
                { type_id: 'I', type_text: 'INTERNAL' },
                { type_id: 'T', type_text: 'CONTRACT' },
                { type_id: 'B', type_text: 'SPECIAL' },
                { type_id: 'G', type_text: 'EXPRESS' }
            ],
            paymentModel: '',
            paymentLists: [],
            dateStartModel: '',
            dateEndModel: '',
            dateStartModal: false,
            dateEndModal: false,
            disabled_region: false,
            disabled_office: false,
            disabled_salesman: false,
            itemLists: [],
            headers: [
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DIVISION', value: 'str3', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORDER NO.', value: 'CustomerOrderNo', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'NamaSales', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ORDER DATE', value: 'TglPesan', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'APPROVE 1', value: 'dt_approve1', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'APPROVE 2', value: 'dt_approve2', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RELEASE', value: 'dt_release', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ALLOW SHIP.', value: 'ship_flag', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'SHIP TO', value: 'DikirimKe', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'showDetails', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            itemDetailLists: [],
            headersDetails: [
                { text: 'NO.', value: 'item_num', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'Panjang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ORDER QTY.', value: 'qty_order', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ADD ID', value: 'add_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'TOR ID', value: 'tor_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ACP ID', value: 'acp_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PLAN QTY.', value: 'qty_plan', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PROD QTY.', value: 'qty_prod', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SHIP QTY.', value: 'qty_ship', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RET. QTY.', value: 'qty_ret', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'STAT', value: 'stat', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'READY TO SHIP', value: 'statADDID', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatableDetail: false,
            searchItem: '',
            loadingDatatable: false,
            detailModalTitle: '',
            dateStartFormatted: '',
            dateEndFormatted: '',
            favoriteCheck: false,
            searchCustomer: null,
            searchOrder: null,
            userGroup: null,
            appl_id: 'E-REPORTS',
            summaryTotalOrder: '',
            summaryWgtOrder: '',
            summaryTotalShip: '',
            summaryWgtShip: '',
            summaryOrdWgtModel: 0,
            summaryShipWgtModel: 0,
            summaryRetWgtModel: 0,
            summaryOutsWgtModel: 0,
            exportToExcelLists: [],
            releaseModel: '',
            releaseLists: [
                { value: '', text: 'ALL' },
                { value: 'Y', text: 'YES' },
                { value: 'N', text: 'NO' }
            ],
            dateTypeModel: 'order',
            headersWgt: [
                { text: 'ORDER WGT', value: 'wgtOrder', align: 'center', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'PLAN WGT', value: 'wgtPlan', align: 'center', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'PROD. WGT', value: 'wgtProd', align: 'center', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'SHIP. WGT', value: 'wgtShip', align: 'center', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'OUTS. WGT', value: 'wgtOuts', align: 'center', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'RETURN WGT', value: 'wgtRetur', align: 'center', class: 'primary--text blue lighten-5', sortable:false },
            ],
            itemWgtLists: [
                {wgtOrder: 0, wgtPlan: 0, wgtProd: 0, wgtShip: 0, wgtOuts: 0, wgtRetur: 0}
            ]
        }
    },
    async mounted(){
        
        this.initialize()
        
    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardResult").style.display = "none"

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.millLists = res.data.mill
                this.paymentLists = res.data.payterm
                this.custDivisionLists = res.data.custDivision

                if(res.data.type == 'R'){

                        this.typeModel = 'PROJECT'
                        this.typeLists = [
                            {
                                value: 'PROJECT',
                                text: 'PROJECT'
                            }
                        ]

                } else {

                        this.typeLists = [
                            {
                                value: '',
                                text: 'All'
                            },
                            {
                                value: 'RETAIL',
                                text: 'RETAIL'
                            },
                            {
                                value: 'PROJECT',
                                text: 'PROJECT'
                            },
                        ]

                }

                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesLists = res.data.sales
                    this.disabled_salesman = false
                }

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })
        },

        async getData(){

            // if(this.millModel == null || this.millModel == ''){
            //     Swal.fire({
            //             text: 'Please Select Mill',
            //             icon: 'warning',
            //         })
            //     return false
            // }

            this.exportToExcelLists = []

            if(this.orderIdModel){

                document.querySelector(".cardResult").style.display = "block";
                this.itemLists = []
                this.loadingDatatable = true
                this.$store.dispatch('setOverlay', true)

                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/getData`, { 

                    orderid: this.orderIdModel ? this.orderIdModel : '',

                },
                {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                })
                .then(res => {
                    if(res.data.result.length != 0){
                        document.querySelector(".cardResult").style.display = "block";
                        this.itemLists = res.data.result
                        this.exportToExcelLists = res.data.result
                        this.itemWgtLists = res.data.summaryWgt
                        // this.summaryOrdWgtModel = res.data.summaryWgt[0].wgtOrder
                        // this.summaryShipWgtModel = res.data.summaryWgt[0].wgtShip
                        // this.summaryRetWgtModel = res.data.summaryWgt[0].wgtRetur
                        this.$store.dispatch('setOverlay', false)
                        this.loadingDatatable = false
                    } else {
                        this.$store.dispatch('setOverlay', false)
                        this.loadingDatatable = false
                        Swal.fire({
                            icon: 'warning',
                            text: 'Data not found !'
                        })
                    }
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                document.querySelector(".cardResult").style.display = "block";
                this.itemLists = []
                this.itemWgtLists = [{wgtOrder: 0, wgtPlan: 0, wgtProd: 0, wgtShip: 0, wgtOuts: 0, wgtRetur: 0}]
                this.loadingDatatable = true
                this.$store.dispatch('setOverlay', true)

                if (this.dateStartModel) {
                    this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
                } else  {
                    this.dateStartFormatted = ''
                }

                if (this.dateEndModel) {
                    this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
                } else  {
                    this.dateEndFormatted = ''
                }

                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/getData`, { 

                        mill: this.millModel ? this.millModel : '',
                        type: this.typeModel ? this.typeModel : '',
                        region: this.regionModel ? this.regionModel : '',
                        office: this.officeModel ? this.officeModel : '',
                        sales: this.salesModel ? this.salesModel : '',
                        customer: this.customerModel ? this.customerModel : '',
                        custDivision:  this.custDivisionModel ? this.custDivisionModel : '',
                        orderid: this.orderIdModel ? this.orderIdModel : '',
                        outstanding: this.outstandingModel ? this.outstandingModel : '',
                        orderType: this.typeOrderModel ? this.typeOrderModel : '',
                        payterm: this.paymentModel ? this.paymentModel : '',
                        dateFrom: this.dateStartFormatted,
                        dateTo: this.dateEndFormatted,
                        isRelease: this.releaseModel ? this.releaseModel : '',
                        dateType: this.dateTypeModel ? this.dateTypeModel : ''

                },
                {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                })
                .then(res => {
                    if(res.data.result.length != 0){
                        document.querySelector(".cardResult").style.display = "block";
                        this.itemLists = res.data.result
                        this.exportToExcelLists = res.data.result
                        this.itemWgtLists = res.data.summaryWgt
                        // this.summaryOrdWgtModel = res.data.summaryWgt[0].wgtOrder
                        // this.summaryShipWgtModel = res.data.summaryWgt[0].wgtShip
                        // this.summaryRetWgtModel = res.data.summaryWgt[0].wgtRetur
                        this.$store.dispatch('setOverlay', false)
                        this.loadingDatatable = false
                    } else {
                        this.$store.dispatch('setOverlay', false)
                        this.loadingDatatable = false
                        Swal.fire({
                            icon: 'warning',
                            text: 'Data not found !'
                        })
                    }
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            }

        },

        async showItemDetails(id){
            $('#detailModal').modal('show')
            this.detailModalTitle = id.NamaCustomer + ' || Order No : ' + id.CustomerOrderNo
            this.itemDetailLists = []
            this.loadingDatatableDetail = true

            this.summaryTotalOrder = 0
            this.summaryWgtOrder = 0
            this.summaryTotalShip = 0
            this.summaryWgtShip = 0

            this.summaryTotalOrder = '0'
            this.summaryWgtOrder = '0'
            this.summaryTotalShip = '0'
            this.summaryWgtShip = '0'

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/getDataDetails`, { 
                    orderid: id.CustomerOrderNo
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.loadingDatatableDetail = false
                this.itemDetailLists = res.data.result
                this.summaryTotalOrder = res.data.summary[0].totQtyOrder
                this.summaryWgtOrder = res.data.summary[0].totWgtOrder
                this.summaryTotalShip = res.data.summary[0].totQtyShip
                this.summaryWgtShip = res.data.summary[0].totWgtShip
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async regionOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/regionOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.officeLists = res.data.office
                this.labelBranch = 'Branch Office(' + res.data.countOffice + ')'
                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async officeOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/officeOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${this.regionModel ? this.regionModel : ''}&office=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async getCustomer(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/getCustomer?customer=${id}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
                })
                .then(res => {
                    // console.log(res.data.result)
                    this.customerLists = res.data.result
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async getOrderID(id)
        {
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/getOrderID?orderid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {

                        // console.log(res.data.result)

                        this.orderIdLists = res.data.result
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

        },

        emptyOrderID(id)
        {
            if(id == null){
                this.orderIdLists = []
            }
        },

        emptyCustomer(id)
        {
            if(id == null){
                this.customerLists = []
            }
        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
            const date = current.toISOString().slice(0,10);
            return date;
        },

        exportToExcel(){

            const data = this.exportToExcelLists
            const fileName = "Order-Report-Excel-Data"
            const exportType = exportFromJSON.types.csv

            if (data) exportFromJSON({ data, fileName, exportType })

            Swal.fire({
                title: 'Success',
                text: 'Data exported successfully, check your download folder.'
            })

        },

    },
    watch: {
        searchCustomer (val) {
            val && val !== this.customerModel && this.getCustomer(val)
        },
        searchOrder (val) {
            val && val !== this.orderIdModel && this.getOrderID(val)
        },
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>